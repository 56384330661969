.portfolio__projects {
  display: flex;
  flex-direction: column;    
}

.portfolio__projects-heading {
  width: 100%;
  text-align: left;

  margin-bottom: 5rem;
}

.portfolio__projects-heading h1 {
  font-size: 62px;
  line-height: 75px;
  font-family: var(--font-family);
  font-weight: 800;
}

.portfolio__projects-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
}



@media screen and (max-width: 1080px) {
  .portfolio__projects-container {
      grid-template-columns: repeat(1, 1fr);
  }

  .portfolio__projects-heading h1 {
      font-size: 46px;
      line-height: 52px;
  }
}

@media screen and (max-width: 700px) {
  .portfolio__projects-container {
      grid-template-columns: repeat(1, 1fr);
  }

  .portfolio__projects-heading h1 {
      font-size: 46px;
      line-height: 52px;
  }
}

@media screen and (max-width: 550px) {
  .portfolio__projects-heading h1 {
      font-size: 34px;
      line-height: 42px;
  }

}