.portfolio__header {
  display: flex;
}

.portfolio__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
  
}

.portfolio__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.portfolio__header-content p {
  margin-top: 1em;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);
  margin-bottom: 1em;
}

.portfolio__header-content__bitsy {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  border-radius: 10px 40px 30px;
}

.portfolio__header-content__bitsy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex: 2; */
  width: 100%;
  height: 90px;
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 27px;
  background: var(--color-footer);
  
  padding-left: 1em;
  outline: none;
  color: #fff;
  
}

.portfolio__header-content__bitsy button {
  flex: 0.6;
  width: 5em;
  height: 90px;
  background: #f92a82;
  font-family: var(--font-family);
  font-style: bold;
  font-size: 16px;
  line-height: 20px;
  color: #FFF;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  border-radius: 20px 20px;
}

.portfolio__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

.portfolio__header-content__people img{
  width: 182px;
  height: 38px;
}

.portfolio__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.portfolio__header-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio__header-image img {
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
  filter: invert(100%);
}

@media screen and (max-width: 1050px) {
  .portfolio__header {
    flex-direction: column;
  }
  .portfolio__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .portfolio__header h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .portfolio__header p {
    font-size: 16px;
    line-height: 24px;
  }
  .portfolio__header-content {
    margin: 0 0 3rem;
  }

  .portfolio__header-content__people {
    flex-direction: column;
  }

  .portfolio__header-content__people p {
    margin: 0;
  }

  .portfolio__header-content__input input,
  .portfolio__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .portfolio__header h1 {
    font-size: 36px;
    line-height: 24px;
  }
  .portfolio__header p {
    font-size: 14px;
    line-height: 24px;
  }
  .portfolio__header-content__input input,
  .portfolio__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}

/* #big-doggo {
  
} */