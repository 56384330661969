.portfolio__blog-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.portfolio__blog-container_article-image {
    width: 100%;
    height: 100%;
    /* background: var(--color-bg); */
    padding-bottom: 10px;
}

.portfolio__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.portfolio__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
    background: var(--color-footer);
}

.portfolio__blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;
    color: #fff;
}
.portfolio__blog-container_article-content p:last-child {
    text-align: end;
}

.portfolio__blog-container_article-content h4 {
    font-family: var(--font-family);
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.portfolio__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .portfolio__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}