.portfolio__charlie {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  background: var(--color-footer);

   /* ff 3.6+ */
   background:-moz-radial-gradient(ellipse at 0% 0%, rgba(31, 45, 93, 1) 48%, rgba(30, 33, 43, 1) 65%, rgba(0, 242, 242, 1) 94%);

   /* safari 5.1+,chrome 10+ */
   background:-webkit-radial-gradient(ellipse at 0% 0%, rgba(31, 45, 93, 1) 48%, rgba(30, 33, 43, 1) 65%, rgba(0, 242, 242, 1) 94%);

   /* opera 11.10+ */
   background:-o-radial-gradient(ellipse at 0% 0%, rgba(31, 45, 93, 1) 48%, rgba(30, 33, 43, 1) 65%, rgba(0, 242, 242, 1) 94%);

   /* ie 10+ */
   background:-ms-radial-gradient(ellipse at 0% 0%, rgba(31, 45, 93, 1) 48%, rgba(30, 33, 43, 1) 65%, rgba(0, 242, 242, 1) 94%);

   /* global 92%+ browsers support */
   background:radial-gradient(ellipse at 0% 0%, rgba(31, 45, 93, 1) 48%, rgba(30, 33, 43, 1) 65%, rgba(0, 242, 242, 1) 94%);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.portfolio__charlie-feature {
  display: flex;
}

/* Customizing feature component as per needs */
.portfolio__charlie-feature .portfolio__features-container__feature {
  margin: 0;
}

/* Customizing feature component as per needs */
.portfolio__charlie-feature .portfolio__features-container_feature-text {
  max-width: 700px;
}

.portfolio__charlie-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  margin: 4rem 0 2rem;
}

.portfolio__charlie-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  max-width: 510px;
}

.portfolio__charlie-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
  text-shadow: 1px 3px 2px #474747;

}

.portfolio__charlie-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-top: 2rem;
}

/* Customizing feature component as per needs */
.portfolio__charlie-container .portfolio__features-container__feature {
  flex: 1;
  margin: 1rem;
  min-width: 210px;
  display: unset;
  flex-direction: column;
}

/* Customizing feature component as per needs */
.portfolio__charlie-container .portfolio__features-container_feature-text {
  margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
  .portfolio__charlie-heading {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0rem;
  }

  .portfolio__charlie-heading p {
      margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  /* Customizing feature component as per needs */
  .portfolio__charlie-feature .portfolio__features-container__feature {
      flex-direction: column;
  }

  /* Customizing feature component as per needs */
  .portfolio__charlie-feature .portfolio__features-container_feature-text {
      margin-top: 0.5rem;
  }

  .portfolio__charlie-heading h1 {
      font-size: 28px;
      line-height: 40px;
  }
}

@media screen and (max-width: 350px) {
  /* Customizing feature component as per needs */
  .portfolio__charlie-container .portfolio__features-container__feature {
      margin: 1rem 0;
      min-width: 100%;
  }
}