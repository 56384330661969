.portfolio__blog-container_modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, .5);
  padding: 50px;
  z-index: 1050;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.portfolio__modal-overlay {
  position: "fixed";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1050;
  /* background-color: rgba(0, 0, 0, .7); */
}

#portfolio__modal-image {
  max-height: 90vh;
  max-width: 90vw;
  
 
}

.modal-button {
  margin: auto;
  
  background: var(--color-subtext);
  color: #FFF; 
  border-radius: 40px;
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  padding: .5rem 1rem;
  margin-top: 1em;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 550px) {
  #portfolio__modal-image {
    height: 50vh;
    width: 80vw;
  }
}