@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;
  --font-bubble: 'Bree Serif', serif;
  --gradient-text: radial-gradient(circle at 0% 2%, rgba(0, 242, 242, 1) 14%, rgba(211, 139, 93, 1) 33%, rgba(249, 42, 130, 1) 89%);
  --gradient-bar: radial-gradient(circle at 0% 0%, rgba(255, 39, 39, 1) 0%, rgba(139, 38, 53, 1) 51%, rgba(0, 242, 242, 1) 95%);
  
  --color-bg: #1E212B;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #fcfafa;
  --color-subtext: #f92a82;
}